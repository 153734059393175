import SearchModule from "components/SearchModule";
import { useCheckBox } from "hooks/useCheckBox";
import useFilter from "hooks/useFilter";
import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { fetchUsers } from "redux/users/api";
import PaginatedTable from "../../components/Table/PaginatedTable";
import { MODULES } from "../../constants/modules";
import { TableConstants } from "./TableConstants";
import * as routes from "constants/routes";
import { fetchCountriesData } from "redux/NewLocation/newLocation";
import { Tooltip } from "@material-ui/core";
import { GrDocumentCsv } from "react-icons/gr";
import moment from "moment";

const User = ({ active, permissionList, isDeleted }: any) => {
  const {
    filter: filterOptions,
    actions: { handleSize, handlePagination, handleSearch, handleSort },
  } = useFilter({
    ordering: "id",
  });
  const history = useHistory();

  const { data: countryDatas } = useQuery(["country"], () => {
    return fetchCountriesData();
  });
  const countryData = countryDatas && !_.isEmpty(countryDatas.data) ? countryDatas?.data : [];

  const { page, paginate_by, ordering: sortField, search: searchQuery } = filterOptions;

  const [refresh, setRefresh] = useState(false);

  const { data, isLoading, refetch, isFetching } = useQuery(
    ["projects", active, filterOptions],
    () => fetchUsers(filterOptions),
    {
      keepPreviousData: true,
    },
  );

  const pageData = data && !_.isEmpty(data?.data) ? data?.data : [];

  const pageDataArray = Array.isArray(pageData) ? pageData : [];

  const modData = pageDataArray.map((item: any) => {
    const countryId = item?.user_profile?.country;
    const countryNames = countryData.find((item: any) => item.id === countryId);
    const countryName = countryNames ? countryNames.name : "";

    const joined = moment(item.date_joined).format("YYYY-MM-DD HH:mm");
    return {
      First_Name: item?.first_name,
      Last_Name: item?.last_name,
      Contact_Number: item.user_profile.contactNumber,
      Email: item?.email,
      Country: countryName,
      Date_Joined: joined,
    };
  });

  const pageCount = (data && data?.count) || 0;
  const totalCount = (data && data?.total_count) || 0;
  const current_page_number = (data && data?.current_page_number) || 0;

  const { checkAll, allChecked } = useCheckBox(page || 1, pageData, refresh, setRefresh);

  const handleExport = () => {
    const fs = require("fs");
    const path = require("path");
    const Papa = require("papaparse");

    const csv = Papa.unparse(modData);

    if (typeof window !== "undefined") {
      // Create a download link in browser environment
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "UsersData.csv";
      link.click();
    } else {
      // Get the user's downloads directory
      const downloadsPath = path.join(require("os").homedir(), "Downloads");

      // Create the file path
      const filePath = path.join(downloadsPath, "UsersDetail.csv");

      // Write the CSV data to the file
      fs.writeFileSync(filePath, csv);
      console.log(`CSV data written to file: ${filePath}`);
    }
  };

  return (
    <>
      <div className="page">
        {/* <div style={{ direction: "rtl" }}>
          <Tooltip title="Export user Info">
            <GrDocumentCsv color="#878787" onClick={handleExport} />
          </Tooltip>
        </div> */}
        <div className="module-header">
          <div className="module-name">User Module</div>
          <div className="module-action">
            <SearchModule handleSearch={handleSearch} />
          </div>
        </div>
        <PaginatedTable
          cols={TableConstants((rowData: any) => history.push(routes.USERS + `/${rowData?.id}`), countryData)}
          data={pageData}
          isDark={false}
          bordered
          striped={false}
          hoverable
          entries={paginate_by || 10}
          onRowClick={() => {}}
          componentName={MODULES.USERS}
          handlePagination={handlePagination}
          handleEntries={handleSize}
          currentPage={current_page_number}
          totalCount={totalCount}
          size="lg"
          isDataLoading={isFetching || isLoading}
          handleSort={handleSort}
          initialSortConfig={{ key: sortField || "", direction: "desc" }}
          allChecked={allChecked}
          checkAll={checkAll}
          isPaginationRequired={true}
        />
      </div>
    </>
  );
};

export default User;
