/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Modal } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import HqmIcon from "components/icon";
import MHCAlert from "components/MHCAlert";
import { Form, FormikProvider, useFormik } from "formik";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchHistoryDetails,
  fetchReviews,
  fetchReviewView,
  postCancellationReason,
  postRemarks,
} from "redux/reviewview/api";
import * as Yup from "yup";
import back from "../../assets/brand/back.png";
import crown from "../../assets/brand/crown.png";
import front from "../../assets/brand/front.png";
import backicon from "../../assets/brand/goback.png";
import left from "../../assets/brand/left.png";
import right from "../../assets/brand/right.png";
import mhcTheme from "../../assets/style/style.module.scss";
import PaginatedTable from "../../components/Table/PaginatedTable";
import config from "../../config";
import { MODULES } from "../../constants/modules";
import HistoryLog from "./HistoryLog";
import "./reviewview.scss";
import { TableConstantsR } from "./TableConstantsR";

import { Stack } from "@mui/material";
import { usePermissionCheck } from "hooks/useCheckPermission";
import ReviewScreen from "layouts/ReviewScreen";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { fetchEmployeeName } from "redux/partners/partnersApi";
import { bulkAssign, fetchProductList } from "redux/reviews/api";
import { reviewScreenActions } from "redux/reviewScreen";
import * as routes from "../../constants/routes";
import { RetakeForm } from "./RetakeForm";

const headSectionMap = {
  front,
  back,
  left,
  right,
};

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "70%",
//   height: "40%",
//   bgcolor: "background.paper",
//   p: 4,
//   padding: "20px 32px 32px 32px",
// };

const ReviewView = ({ view, setView, fetchUserProductDataList, active, permissionList }: any) => {
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const customIdd = "custom-id-yes";
  const review_data = sessionStorage.getItem("review_data");

  const state = useMemo(() => {
    try {
      return review_data ? { data: JSON.parse(review_data) } : { data: undefined };
    } catch (error) {
      return { data: undefined };
    }
  }, [review_data]);

  React.useEffect(() => {
    if (state.data) {
      history.goBack();
    }
  }, [history, state]);

  const patient_user_profile_id = state?.data?.id;

  const [paginateBy, setPaginateBy] = useState(config.PAGE_SIZE);
  const [viewview, setViewview] = useState<any>(false);

  const [openHistory, setOpenHistory] = useState<boolean>(false);

  const [retake, setCancel] = useState(false);
  const [forwardSelected, setForwardSelected] = useState(false);
  const [reviewSelected, setReviewSelected] = useState(false);
  const customId = "custom-id-yes";

  // permission check
  const view_hairimagereviewlogs = usePermissionCheck("view_hairimagereviewlogs");
  const can_assign = usePermissionCheck("");
  const is_assigned = true;

  const view_hairimagescores = usePermissionCheck("view_hairimagescores");
  const change_hairimagereview = usePermissionCheck("change_hairimagereview");

  const { mutate: cancelReasonApi, isLoading: isCancelLoading } = useMutation(postCancellationReason, {
    onSuccess() {
      setCancel(false);
      toast("Review Cancelled", {
        toastId: customId,
      });
      setTimeout(() => {
        history.goBack();
      }, 1000);
    },
    onError(err: any) {
      toast(JSON.stringify(err?.response?.data?.message), { toastId: customId });
    },
  });

  const handleCancellationSubmit = async ({ analysis, causes, suggestions }: any) => {
    const cancellation_reasons = [...analysis, ...causes, ...suggestions];
    cancelReasonApi({
      id: state?.data?.id,
      data: { cancellation_reasons, status: "CANCELLED" },
    });
  };

  const handleviewview = (row: any) => {
    setViewview(row);
  };

  const {
    data: reviewData,
    isFetching,
    refetch: fetchUserDataList,
  } = useQuery(["fetch user data", state?.data?.id], () => fetchReviewView(state?.data?.id), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { mutate: assignToPost } = useMutation(bulkAssign, {
    onSuccess() {
      toast.success("Assigned Successfully", {
        toastId: customIdd,
        autoClose: 3000,
        pauseOnFocusLoss: false,
      });
      setTimeout(() => {
        history.push(routes.REVIEWS_PENDING);
      }, 1000);

      fetchUserProductDataList();
    },
    onError(error) {
      let a = error as any;
      toast(a.response?.data?.message, {
        toastId: customIdd,
        autoClose: 3000,
        pauseOnFocusLoss: false,
      });
    },
  });

  const { mutate: UpdateRemarks, isLoading } = useMutation(postRemarks, {
    onSuccess() {
      toast.success("Action Completed Successfully", {
        toastId: customId,
      });

      setTimeout(() => {
        history.push(routes.REVIEWS_PENDING);
      }, 1000);
    },
    onError(error) {
      let a = error as any;
      if (a.response.data.description.errors[0].remarks) {
        toast.error(a.response.data.description.errors[0].remarks, {
          toastId: customId,
        });
      } else {
        toast(a.response?.data?.message, {
          toastId: customId,
        });
      }
    },
  });

  const { data: productList } = useQuery(["fetch product list"], fetchProductList);
  const { data: forwardto } = useQuery(["fetchEmployeeName"], fetchEmployeeName);

  const pageData = reviewData && !_.isEmpty(reviewData.data) ? reviewData.data : [];

  const totalCount = (reviewData && reviewData.data?.total_count) || 0;
  const current_page_number = (reviewData && reviewData.data?.current_page_number) || 0;

  const handlePagination = () => {};
  const handleEntries = () => {};
  const handleSort = (key: string, direction: string) => {};

  const headSectionsAll: any[] = useMemo(() => {
    try {
      return reviewData.data?.map((item: any) => item.haircapture?.head_section_code);
    } catch (error) {
      return [];
    }
  }, [reviewData?.data]);

  // const headSections = headSectionsAll.filter((item) => !item.includes("1"));

  let arr = Object.values(headSectionsAll);

  const front_index = arr?.indexOf("front");
  const back_index = arr?.indexOf("back");
  const left_index = arr?.indexOf("left");
  const right_index = arr?.indexOf("right");
  const crown_index = arr?.indexOf("crown");

  const upper_array = ["1A", "1B", "1C", "1D", "1E", "1F", "1G"];

  const pageData2 = pageData.filter((item: any) => {
    return upper_array.includes(item?.haircapture?.head_section_code);
  });

  const formik = useFormik({
    initialValues: {
      remarks: state?.data?.remarks || "",
      package: state?.data?.cost || "",
      id: state?.data?.id || "",
      status: "REVIEWED",
      forwarded_to: "",
    },
    validationSchema: Yup.lazy((values) => {
      if (forwardSelected) {
        return Yup.object({
          forwarded_to: Yup.string().required("Forwarded to is required"),
        });
      } else {
        return Yup.object({
          package: Yup.string().required("At least one package required"),
          remarks: Yup.string().max(500).required("Remarks is required"),
        });
      }
    }),
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (forwardSelected) {
        // this condition runs when user forwards to any of operators
        delete values.package;
        delete values.status;
        delete values.remarks;
        handleAssignTo(values);
      } else {
        handleRemarksSubmit({ ...values, id: state?.data?.id });
      }
    },
  });

  const handleRemarksSubmit = (values: any) => {
    //make changes you've made in the api here
    UpdateRemarks(values);
  };

  const handleAssignTo = (values: any) => {
    assignToPost({ ids: [values.id], assigned_to: parseInt(values.forwarded_to) });
  };

  const handleback = () => {
    let currentUrl = window.location.href;
    let splicedArray = currentUrl.split("/");
    const redirectLink = splicedArray.filter((item, index) => {
      if (index > 2 && index < 6) {
        return item;
      }
    });
    let finalRedirectLink = redirectLink.join("/").toString();
    finalRedirectLink = "/" + finalRedirectLink;

    // eslint-disable-next-line no-restricted-globals
    history.push(finalRedirectLink);
  };

  const { data: historyDetails } = useQuery(["fetch history"], () => fetchHistoryDetails(state.data?.id));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: capturedData, refetch: refetchCapturedData } = useQuery(
    ["capturedData", patient_user_profile_id, viewview],
    () => fetchReviews(patient_user_profile_id),
  );
  const patientsCapturedData = capturedData && !_.isEmpty(capturedData.data) ? capturedData.data : [];

  const handleClickOpenCancel = () => {
    setCancel(true);
  };

  const handleCloseCancel = () => {
    setCancel(false);
  };

  const handleHistoryClick = () => {
    setOpenHistory(true);
    historyDetails ? <></> : toast("No log to view", { toastId: customId });
  };
  const selectFowardOption = () => {
    setForwardSelected(true);
  };
  const handleReviewOption = () => {
    setReviewSelected(true);
  };

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} autoClose={3000} />
      {openHistory ? (
        <HistoryLog view={view} historyDetail={historyDetails} setOpenHistory={setOpenHistory} />
      ) : (
        <>
          <Modal style={{ overflowY: "auto" }} open={Boolean(viewview)}>
            <ReviewScreen
              imageData={pageData}
              selected={viewview?.haircapture?.head_section_code}
              onClose={() => {
                setViewview(false);
                dispatch(reviewScreenActions.toggleHGrid(false));
                dispatch(reviewScreenActions.toggleVGrid(false));
              }}
              view={view}
              refetchPageData={fetchUserDataList}
            />
          </Modal>

          <div className="page">
            <div className="reviewview_headSection">
              <div className="reviewview_headSection_img_name_date">
                <img onClick={() => handleback()} src={backicon} alt="/" />
                <h3>{state.data?.user_profile.full_name} </h3>
                <h5>{state.data?.capture_date.substring(0, 10)}</h5>
              </div>

              {view_hairimagescores ? (
                <div className="reviewview_headSection_overallValues">
                  <div className="overallhairValues">
                    MyHairStatus <h5>{patientsCapturedData?.overall_dandruff}</h5>
                  </div>
                  <div className="overallhairValues">
                    Density{" "}
                    <h5>
                      {patientsCapturedData?.overall_density
                        ? parseFloat(patientsCapturedData?.overall_density).toFixed(2)
                        : null}
                    </h5>
                  </div>
                  <div className="overallhairValues">
                    MyGrowthScore <h5>{patientsCapturedData?.overall_gray_hair}</h5>
                  </div>
                </div>
              ) : null}
            </div>

            <PaginatedTable
              // cols={TableConstantsR(handleviewview, handleOpenModal)}
              cols={TableConstantsR()}
              data={pageData2}
              isDark={false}
              bordered
              striped={false}
              hoverable
              entries={paginateBy}
              onRowClick={(item: any) => {
                setViewview(item);
              }}
              //selected={selectedRowID}
              componentName={MODULES.REVIEWS_VIEW}
              handlePagination={handlePagination}
              handleEntries={handleEntries}
              currentPage={current_page_number}
              totalCount={totalCount}
              size="lg"
              isDataLoading={isFetching}
              handleSort={handleSort}
            />

            <div className="reviewview-sections">
              {headSectionsAll.includes("crown") && (
                <div className="frame1" onClick={() => handleviewview(reviewData.data[crown_index])}>
                  <img className="frame1-head-section-image" src={crown} alt="" />
                  <div className="flexitem-row">
                    {reviewData.data[crown_index].remarks
                      ? `${reviewData.data[crown_index].remarks.substring(0, 12)} ...`
                      : "N/A"}
                  </div>
                  {/* <HqmIcon
                    className="flexitem-row"
                    name="edit"
                    size={20}
                    style={{ color: mhcTheme.secondary, cursor: "pointer" }}
                    onClick={() => handleviewview(reviewData.data[crown_index])}
                  /> */}
                </div>
              )}
              {headSectionsAll.includes("front") ? (
                <div className="frame1" onClick={() => handleviewview(reviewData.data[front_index])}>
                  <img className="frame1-head-section-image" src={front} alt="" />
                  <div className="flexitem-row">
                    {reviewData.data[front_index].remarks
                      ? `${reviewData.data[front_index].remarks.substring(0, 12)} ...`
                      : "N/A"}
                  </div>
                </div>
              ) : null}

              {headSectionsAll.includes("back") ? (
                <div className="frame1" onClick={() => handleviewview(reviewData.data[back_index])}>
                  <img className="frame1-head-section-image" src={back} alt="" />
                  <div className="flexitem-row">
                    {reviewData.data[back_index].remarks
                      ? `${reviewData.data[back_index].remarks.substring(0, 12)} ...`
                      : "N/A"}
                  </div>
                </div>
              ) : null}

              {headSectionsAll.includes("left") ? (
                <div className="frame1" onClick={() => handleviewview(reviewData.data[left_index])}>
                  <img className="frame1-head-section-image" src={left} alt="" />
                  <div className="flexitem-row">
                    {reviewData.data[left_index].remarks
                      ? `${reviewData.data[left_index].remarks.substring(0, 12)} ...`
                      : "N/A"}
                  </div>
                </div>
              ) : null}

              {headSectionsAll.includes("right") ? (
                <div className="frame1" onClick={() => handleviewview(reviewData.data[right_index])}>
                  <img className="frame1-head-section-image" src={right} alt="" />
                  <div className="flexitem-row">
                    {reviewData.data[right_index].remarks
                      ? `${reviewData.data[right_index].remarks.substring(0, 12)} ...`
                      : "N/A"}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="reviewview-section_1">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <div>
                    <div className="frame2_section2">
                      <div className="referral_points_display">
                        <div>
                          {view_hairimagereviewlogs ? (
                            <h5>
                              <span onClick={handleHistoryClick}>History</span>
                            </h5>
                          ) : null}
                          <h5>Total Referral Points: {state.data?.user_profile.total_referral_points} </h5>
                        </div>

                        {/* <div className="frame2">
                  <div className="frame2_text">
                    <h5>Stylist Details</h5>
                    Spa/Saloon :
                    {state.data?.hair_capture?.license_number
                      ? state.data?.hair_capture?.license_number.substring(0, 10)
                      : " N/A"}
                    <br />
                    Stylist{" "}
                    {state.data?.hair_capture?.license_number
                      ? state.data?.hair_capture?.license_number.substring(0, 10)
                      : "N/A"}
                  </div>
                </div> */}
                      </div>

                      <div>
                        {is_assigned &&
                          !reviewSelected && ( // TODO: make this false
                            <div className="frame2">
                              <h5 style={{ fontWeight: 600 }}>Assign To</h5>

                              <select
                                style={{ width: "300px", height: "40px" }}
                                name="forwarded_to"
                                onChange={(e) => {
                                  setForwardSelected(Boolean(e.target.value));
                                  formik.handleChange(e);
                                }}
                                disabled={reviewSelected}
                                onBlur={formik.handleBlur}
                                placeholder="---"
                                value={formik.values?.forwarded_to || ""}
                              >
                                {/* <option selected value={productList_cost_id}>-----</option> */}
                                <option value="">----</option>
                                {forwardto?.map((item: any, index) => (
                                  <option key={`forwardSelect${index}`} value={item?.id}>
                                    {String(item?.first_name) + " " + String(item?.last_name)}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        {!forwardSelected && (
                          <div className="frame2">
                            <h5 style={{ fontWeight: 600 }}>Cost</h5>

                            <select
                              name="package"
                              onChange={(e) => {
                                setReviewSelected(Boolean(e.target.value));
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="---"
                              disabled={forwardSelected}
                              value={formik.values?.package || ""}
                              style={{ width: "300px", height: "40px" }}
                            >
                              {/* <option selected value={productList_cost_id}>-----</option> */}
                              <option value="">----</option>
                              {productList?.map((item: any, index: number) => (
                                <option key={`productList${index}`} value={item.id}>
                                  {String(item.package_name) + " " + String(item.package_cost)}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="frame2">
                        <h5 style={{ fontWeight: 600 }}>Remarks</h5>
                        <textarea
                          cols={6}
                          style={{ width: 498 }}
                          className={
                            formik.errors && formik.errors?.remarks && formik.touched && formik.touched?.remarks
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="remarks"
                          value={formik.values?.remarks || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors && formik.errors?.remarks && formik.touched && formik.touched?.remarks && (
                          <div className="invalid-feedback">{formik.errors?.remarks || ""}</div>
                        )}
                      </div>
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <Stack direction={"row"} gap={4} alignItems="center" justifyContent={"space-between"}>
                        {change_hairimagereview ? (
                          <button type="submit" className="employee-button-review" disabled={isLoading}>
                            {forwardSelected ? (
                              <span style={{ marginRight: isLoading ? "0.5rem" : 0 }}>Assign</span>
                            ) : (
                              <span style={{ marginRight: isLoading ? "0.5rem" : 0 }}>Approve</span>
                            )}
                            {isLoading && <Spinner animation="border" size="sm" />}
                          </button>
                        ) : null}
                        {is_assigned ? (
                          <button type="button" onClick={handleClickOpenCancel} className="retake-btn">
                            Retake
                          </button>
                        ) : null}
                      </Stack>

                      <Dialog
                        open={retake}
                        fullWidth
                        maxWidth="xl"
                        onClose={handleCloseCancel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <RetakeForm
                          loading={isCancelLoading}
                          onClose={handleCloseCancel}
                          onSubmit={(values) => {
                            handleCancellationSubmit(values);
                          }}
                        />
                      </Dialog>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>
            <MHCAlert />
          </div>
        </>
      )}
    </>
  );
};

export default ReviewView;
